import { ajaxPost, ajaxGet } from "core/js/spring-ajax";

const LOGIN_CREDENTIALS_URL = "/authentication/rest/login/credentials";
const LOGIN_TOKEN_URL = "/authentication/rest/login/token";
const LOGIN_GOOGLE_URL = "/authentication/rest/login/google";
const LOGIN_PROPERTIES_URL = "/authentication/rest/login/properties";

export const SSO_LOGIN_PATH = "/authentication/sso/saml/initiate";

export type LoginStatus = "SUCCESS" | "FAILURE" | "TOKEN_VERIFICATION_REQUIRED";

export type FailureReason = "INVALID_CREDENTIALS" | "INVALID_TOKEN" | "OTHER";

interface LoginFailure {
  failureReason: FailureReason
  message?: string;
}

export interface LoginResult {
  loginStatus: LoginStatus;
  loginFailure?: LoginFailure;
  throttleTimeoutSeconds?: number;
  redirectUrl?: string
}

export interface LoginProperties {
  systemAreaName: string;
  samlEnabled: boolean;
  ipWhitelistEnabled: boolean;
  forgotPasswordUrl: string;
  registrationUrl?: string;
  systemMessageHtml?: string;
  googleAuthClientId?: string;
}

export async function loginWithCredentials(username: string, password: string): Promise<LoginResult> {
  return ajaxPost({
    url: LOGIN_CREDENTIALS_URL,
    data: { username, password },
  });
}

export async function loginWithToken(token: string): Promise<LoginResult> {
  return ajaxPost({
    url: `${LOGIN_TOKEN_URL}?token=${token}`,
    data: {},
  });
}

export async function loginWithGoogle(idToken: string): Promise<LoginResult> {
  return ajaxPost({
    url: `${LOGIN_GOOGLE_URL}?idToken=${idToken}`,
    data: {},
  });
}

export async function getLoginProperties(): Promise<LoginProperties> {
  return ajaxGet({ url: LOGIN_PROPERTIES_URL });
}