<template>
  <login-layout :show-notifications="true" :loading="isPerformingAction" :loading-text="loadingText">
    <template #header>
      <div v-if="signInWithCredentials" class="content-actions">
        <ul class="menu-out flow-across">
          <li>
            <a href="#" class="prev-link" @click.prevent="resetLoginMethod">Back</a>
          </li>
        </ul>
      </div>
    </template>

    <template #body>
      <div v-if="store.multipleLoginOptionsExist && !chosenLoginMethod"
           class="ecase-sign-in__form-action-group ecase-sign-in__form-action-group--vertical">
        <atom-button v-if="store.loginProperties.samlEnabled" label="Sign into eCase" type="primary"
                     @click.prevent="chooseSignInWithSso"/>
        <template v-else-if="store.loginProperties.googleAuthClientId">
          <google-login-button v-if="googleAuthLibraryLoaded"/>
          <div v-else class="icon-animated-spinner blue-icon delayed-loading-indicator"/>
        </template>
        <atom-button label="Sign into eCase without SSO" type="link-button" @click.prevent="chooseSignInWithCredentials"/>
      </div>

      <template v-if="!store.multipleLoginOptionsExist || signInWithCredentials">
        <template v-if="tokenVerificationRequired">
          <verify-token-confirmation :username="username"/>
        </template>

        <template v-else>
          <template v-if="loginFailure">
            <atom-info-box v-if="invalidCredentials" priority="danger" role="alert">
              Sign in attempt failed. This could mean:
              <ul>
                <li>Your username is incorrect</li>
                <li>Your password is incorrect (N.B. passwords are case sensitive)</li>
                <li>Your account has been suspended (you will have received an email if this is the case)</li>
              </ul>
            </atom-info-box>

            <atom-info-box v-else-if="validationError.message" priority="danger" role="alert">
              {{ validationError.message }}
            </atom-info-box>

            <atom-info-box v-else-if="!userThrottled" priority="danger" role="alert">
              The user account specified cannot be used at this time.
            </atom-info-box>
          </template>

          <atom-info-box v-if="isThrottleTimeoutActive" priority="warning">
            <p class="ecase-sign-in__body-text">
              Please wait <strong>{{ throttleTimeoutSeconds }} seconds</strong> before re-attempting sign in on this device.
            </p>
            <p class="ecase-sign-in__body-text">
              For security reasons eCase restricts sign-in attempts when unusual behaviour is detected.
            </p>
          </atom-info-box>

          <form method="POST">
            <atom-text-input id="username" v-model="username" label="Email address" autocomplete="username"
                             :error-message="validationError.username"/>

            <atom-text-input id="password" v-model="password" type="password" label="Password" autocomplete="current-password"
                             :error-message="validationError.password"/>

            <div class="ecase-sign-in__form-action-group">
              <atom-button label="Sign in" type="primary" button-type="submit" :disabled="disableButtons || undefined"
                           @click.prevent="submitCredentials"/>
              <atom-button role="link" label="Forgot your password?" type="link-button"
                           @click.prevent="navigateToForgotPassword"/>
            </div>
          </form>
        </template>
      </template>
    </template>

    <template #footer>
      <a v-if="store.loginProperties.registrationUrl" :href="foxUrl(store.loginProperties.registrationUrl)">
        Register for an account
      </a>
    </template>
  </login-layout>
</template>

<script>
import LoginLayout from "authentication/components/login/layout/LoginLayout.vue";
import AtomTextInput from "core/components/atom/textInput/AtomTextInput.vue";
import AtomButton from "core/components/atom/button/AtomButton.vue";
import AtomInfoBox from "core/components/atom/infoBox/AtomInfoBox.vue";
import GoogleLoginButton from "authentication/components/login/components/GoogleLoginButton.vue";
import { SSO_LOGIN_PATH, loginWithCredentials } from "authentication/js/api/login.api.ts";
import { getFirstValidationError } from "core/js/validation/validation-messages.js";
import { required } from "@vuelidate/validators";
import springUrl, { foxUrl } from "core/js/spring-url.js";
import { useVuelidate } from "@vuelidate/core";
import { template } from "lodash";
import VerifyTokenConfirmation from "authentication/components/login/components/VerifyTokenConfirmation.vue";
import { useLoginPropertiesStore } from "authentication/stores/login-properties.store";
import { useGoogleAuthScript } from "authentication/js/composable/google-auth-script";
import showFlashMessage from "core/js/flash-message";
import announce from "core/js/announce";
import { PASSWORD_CHANGED_PARAM } from "authentication/js/api/password.api";

const ONE_SECOND = 1000; // ms

const LoginMethod = {
  CREDENTIALS: "CREDENTIALS",
  SSO: "SSO",
};

export default {
  components: {
    LoginLayout,
    AtomInfoBox,
    AtomTextInput,
    AtomButton,
    GoogleLoginButton,
    VerifyTokenConfirmation,
  },
  setup() {
    const store = useLoginPropertiesStore();
    const {
      googleAuthLibraryLoaded,
      loggingInWithGoogle,

      loadGoogleAuthScript,
    } = useGoogleAuthScript();

    return {
      store,
      googleAuthLibraryLoaded,
      loggingInWithGoogle,

      loadGoogleAuthScript,

      v$: useVuelidate(),
    };
  },
  data() {
    return {
      username: "",
      password: "",
      disableButtons: false,
      chosenLoginMethod: undefined,
      loginStatus: undefined,
      validationError: {},
      userThrottled: false,
      throttleTimeoutSeconds: 0,
      throttleTimeoutId: undefined,
    };
  },
  computed: {
    signInWithCredentials() {
      return this.chosenLoginMethod === LoginMethod.CREDENTIALS;
    },
    loginSuccess() {
      return this.loginStatus === "SUCCESS";
    },
    loginFailure() {
      return this.loginStatus === "FAILURE";
    },
    tokenVerificationRequired() {
      return this.loginStatus === "TOKEN_VERIFICATION_REQUIRED";
    },
    invalidCredentials() {
      return this.validationError && this.validationError.reason === "INVALID_CREDENTIALS";
    },
    isThrottleTimeoutActive() {
      return this.throttleTimeoutSeconds && this.throttleTimeoutSeconds > 0;
    },
    isPerformingAction() {
      return this.loggingInWithGoogle;
    },
    loadingText() {
      if (this.loggingInWithGoogle) {
        return "Signing in with Google...";
      }

      return undefined;
    },
  },
  watch: {
    throttleTimeoutSeconds(timeoutSeconds) {
      if (timeoutSeconds > 0) {
        this.throttleTimeoutId = setTimeout(() => {
          this.throttleTimeoutSeconds -= 1;
        }, ONE_SECOND);
      }
    },
  },
  validations() {
    return {
      username: { required },
      password: { required },
    };
  },
  async created() {
    await this.store.fetchLoginProperties();
    if (this.store.loginProperties.googleAuthClientId) {
      this.loadGoogleAuthScript();
    }
  },
  mounted() {
    if (PASSWORD_CHANGED_PARAM in this.$route.query) {
      showFlashMessage("Your password has been changed", { displayType: "success" });
    }
  },
  methods: {
    foxUrl,
    chooseSignInWithSso() {
      window.location.assign(springUrl(SSO_LOGIN_PATH));
    },
    chooseSignInWithCredentials() {
      this.chosenLoginMethod = LoginMethod.CREDENTIALS;
      this.loginStatus = undefined;
      this.username = "";
      this.password = "";
      this.throttleTimeoutSeconds = 0;
    },
    resetLoginMethod() {
      this.chosenLoginMethod = undefined;
    },
    navigateToForgotPassword() {
      this.$router.push({ name: "forgot-password-page" });
    },
    async submitCredentials() {
      this.validationError = {};
      this.disableButtons = true;

      try {
        if (await this.v$.$validate()) {
          if (this.throttleTimeoutId) {
            clearTimeout(this.throttleTimeoutId);
            this.throttleTimeoutSeconds = 0;
          }

          const loginResult = await loginWithCredentials(this.username, this.password);
          this.loginStatus = loginResult.loginStatus;

          if (this.loginSuccess) {
            window.location.assign(loginResult.redirectUrl);
          } else if (this.loginFailure) {
            this.password = "";
            this.v$.$reset();
            this.validationError = {
              reason: loginResult.loginFailure.failureReason,
              message: loginResult.loginFailure.message,
            };

            this.throttleTimeoutSeconds = loginResult.throttleTimeoutSeconds;
            this.userThrottled = this.isThrottleTimeoutActive;

            if (this.throttleTimeoutSeconds) {
              announce(`Please wait ${this.throttleTimeoutSeconds} seconds before re-attempting sign in on this device. `
                  + `For security reasons eCase restricts sign-in attempts when unusual behaviour is detected.`);
            }
          }
        } else {
          this.validationError = {
            username: getFirstValidationError(
              this.v$.username,
              "email address",
              { required: template("Enter an email address") },
            ),
            password: getFirstValidationError(this.v$.password, "password"),
          };
          announce(`There were some errors with your submission. ${this.validationError.username || ""}, `
              + `${this.validationError.password || ""}`);
        }
      } finally {
        this.disableButtons = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.content-actions:only-child {
  margin: -1em -1em 1em;
}

</style>
