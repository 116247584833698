<template>
  <div>
    <div class="pre-login-page small-pre-login-page">
      <div id="pre-login-page-logo">
        <h1 id="pre-login-page-ecase-logo">
          <ecase-logo/>
        </h1>

        <div id="pre-login-page-customer-branding">
          <div id="pre-login-page-customer-name">
            {{ store.loginProperties.systemAreaName }}
          </div>
        </div>
      </div>

      <div>
        <slot name="header"/>
      </div>

      <div v-if="showNotifications" id="pre-login-page-system-notifications">
        <div v-if="store.loginProperties.systemMessageHtml">
          <atom-info-box priority="warning">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="store.loginProperties.systemMessageHtml"/>
          </atom-info-box>
        </div>
      </div>

      <!-- TODO Look into replacing the loading spinner with Ghosting -->
      <div v-if="!propertiesFetchComplete"
           class="ecase-sign-in__spinner icon-animated-spinner blue-icon delayed-loading-indicator">
        Loading...
      </div>

      <div v-else-if="loading" class="ecase-sign-in__spinner icon-animated-spinner blue-icon delayed-loading-indicator">
        {{ loadingText }}
      </div>

      <div v-else>
        <slot name="body"/>
      </div>
    </div>

    <div id="pre-login-page-footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import AtomInfoBox from "core/components/atom/infoBox/AtomInfoBox.vue";
import EcaseLogo from "authentication/components/login/components/EcaseLogo.vue";
import { useLoginPropertiesStore } from "authentication/stores/login-properties.store";

export default {
  components: { AtomInfoBox, EcaseLogo },
  props: {
    loading: { type: Boolean, default: false },
    loadingText: { type: String, default: "Loading..." },
    showNotifications: { type: Boolean, default: false },
  },
  setup() {
    return { store: useLoginPropertiesStore() };
  },
  data() {
    return { propertiesFetchComplete: false };
  },
  async created() {
    await this.store.fetchLoginProperties();
    this.propertiesFetchComplete = true;
  },
};
</script>

<style src="./LoginLayout.scss" lang="scss"/>
