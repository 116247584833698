<template>
  <div>
    <div class="setoutForm regular-form-width">
      <div class="row">
        <div class="twelve columns">
          <p>
            eCase supports the SAML 2.0 protocol for Single Sign On (SSO). Before enabling SAML on this page, you must configure
            your Identity Provider (IdP) for use with eCase. The following Identity Providers have been tested with eCase
            and have detailed configuration instructions:
          </p>
          <ul class="padded-list">
            <li><a href="https://www.fivium.co.uk/ecasehelp/saml-single-sign/ad-fs" target="_blank">Windows AD FS</a></li>
            <li><a href="https://www.fivium.co.uk/ecasehelp/saml-single-sign/azure-ad" target="_blank">Azure AD</a></li>
            <li><a href="https://www.fivium.co.uk/ecasehelp/saml-single-sign/g-suite" target="_blank">Google G Suite</a></li>
          </ul>
          <p>
            If your Identity Provider is not listed above, you should
            <a href="https://www.fivium.co.uk/ecasehelp/saml-single-sign/configuration-options-other-idps" target="_blank">
              follow the configuration instructions for other IdPs</a>.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="four columns" />
        <div class="eight columns" :class="{'input-error': validationErrors['enabled']}">
          <label class="radio-label" for="saml-enabled-disabled">
            <input id="saml-enabled-disabled" v-model="settings.enabled" type="radio"
                   name="saml-enabled" :value="false"> Disable SAML
          </label>
        </div>
      </div>
      <div class="row">
        <div class="four columns" />
        <div class="eight columns" :class="{'input-error': validationErrors['enabled']}">
          <label class="radio-label" for="saml-enabled-enabled">
            <input id="saml-enabled-enabled" v-model="settings.enabled" type="radio"
                   name="saml-enabled" :value="true">Enable SAML
          </label>
          <div v-if="validationErrors['enabled']" class="error-message">
            {{ validationErrors['enabled'] }}
          </div>
        </div>
      </div>
      <div v-if="showDisableNotAllowedMessage" class="row">
        <div class="four columns" />
        <div class="eight columns">
          <div class="info-box info-box-danger">
            You are not allowed to disable SAML because credential based login is not enabled for your account. Disabling
            SAML will result in you losing access to eCase.
          </div>
        </div>
      </div>
      <div v-if="showCredentialLoginEnabledWarning" class="row">
        <div class="four columns" />
        <div class="eight columns">
          <div class="info-box info-box-warning">
            Some of your users are still able to log in to eCase without using your SAML IdP. Please contact support if
            you require this setting to be changed.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns">
          <label class="prompt-west" for="idp-url">IdP Endpoint URL</label>
        </div>
        <div class="eight columns" :class="{'input-error': validationErrors['idpUrl']}">
          <input id="idp-url" v-model="settings.idpUrl" type="text" name="idp-url">
          <div v-if="validationErrors['idpUrl']" class="error-message">
            {{ validationErrors['idpUrl'] }}
          </div>
          <div class="fieldDescription">
            This is the URL which eCase will use to initiate the Single Sign On process
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns">
          <label class="prompt-west" for="idp-entity-id">IdP Entity ID</label>
        </div>
        <div class="eight columns" :class="{'input-error': validationErrors['idpEntityId']}">
          <input id="idp-entity-id" v-model="settings.idpEntityId" type="text" name="idp-entity-id">
          <div v-if="validationErrors['idpEntityId']" class="error-message">
            {{ validationErrors['idpEntityId'] }}
          </div>
          <div class="fieldDescription">
            This is the ID your IdP uses when sending a SAML Response to eCase
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns">
          <label class="prompt-west" for="primary-x509-cert">IdP X.509 Certificate</label>
        </div>
        <div class="eight columns" :class="{'input-error': validationErrors['primaryX509Certificate']}">
          <textarea id="primary-x509-cert" v-model="settings.primaryX509Certificate" name="primary-x509-cert" rows="8" />
          <div v-if="validationErrors['primaryX509Certificate']" class="error-message">
            {{ validationErrors['primaryX509Certificate'] }}
          </div>
          <div class="fieldDescription">
            This is the Base64 encoded public key of the certificate your IdP uses to sign SAML Responses
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns" />
        <div class="eight columns">
          <input id="show-secondary-certificate" v-model="showSecondaryCertificate" type="checkbox">
          <label id="show-secondary-certificate-label" for="show-secondary-certificate" class="radio-label">
            I have a secondary X.509 certificate
          </label>
          <div class="fieldDescription">
            Check this box to provide an additional X.509 certificate for rollover management
          </div>
        </div>
      </div>
      <div v-if="showSecondaryCertificate" class="row">
        <div class="four columns">
          <label class="prompt-west" for="secondary-x509-cert">Secondary IdP X.509 Certificate</label>
        </div>
        <div class="eight columns" :class="{'input-error': validationErrors['secondaryX509Certificate']}">
          <textarea id="secondary-x509-cert" v-model="settings.secondaryX509Certificate" name="secondary-x509-cert" rows="8" />
          <div v-if="validationErrors['secondaryX509Certificate']" class="error-message">
            {{ validationErrors['secondaryX509Certificate'] }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns">
          <label class="prompt-west" for="sp-endpoint-url">SP Endpoint URL</label>
        </div>
        <div class="eight columns">
          <input id="sp-endpoint-url" type="text" name="sp-endpoint-url" readonly="readonly"
                 :value="urls.samlResponseUrl" onfocus="this.select()">
          <div class="fieldDescription">
            You should configure your IdP to send SAML responses to this URL
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns">
          <label class="prompt-west" for="sp-entity-id">SP Entity ID</label>
        </div>
        <div class="eight columns" :class="{'input-error': validationErrors['spEntityId']}">
          <input id="sp-entity-id" v-model="settings.spEntityId" type="text" name="idp-entity-id">
          <div v-if="validationErrors['spEntityId']" class="error-message">
            {{ validationErrors['spEntityId'] }}
          </div>
          <div class="fieldDescription">
            You should configure your IdP to recognise eCase using this ID
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns" />
        <div class="eight columns">
          <button :disabled="testButtonDisabled || undefined" @click.prevent="startTest">
            Test SAML Settings
          </button>
        </div>
      </div>
      <div v-if="testRequired" class="row">
        <div class="four columns" />
        <div class="eight columns">
          <div class="info-box info-box-info">
            You must test your changes to the SAML settings before you can save them.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="four columns" />
        <div class="eight columns">
          <button class="primary-button" :disabled="saveDisabled || undefined" @click.prevent="saveSettings">
            Save<span class="screen-reader-only"> changes to SAML settings</span>
          </button>
          <a class="button link-button" href="#" @click.prevent="backClick">
            Cancel<span class="screen-reader-only"> changes to SAML settings</span>
          </a>
        </div>
      </div>
    </div>

    <modal-popover id="startTest" title="Test SAML settings" :displayed-id="modalDisplayedId" @close-modal="closeTestModal">
      <template v-slot:content>
        <div v-if="!testStarted">
          <p>Click the link below to open your IdP's Single Sign On page and log in using your credentials.</p>
          <p><a href="#" class="button primary-button" @click.prevent="openIdpWindow">Open IdP Single Sign On page</a></p>
        </div>
        <div v-else class="info-box info-box-info">
          <p>To complete the test, log in to your IdP's Single Sign On page in the other tab or window.</p>
        </div>
        <p>If the Single Sign On page does not appear, you should update the IdP endpoint URL.</p>
        <p>If any errors occur on the IdP Single Sign On page, check your IdP's configuration or error logs.</p>
      </template>
    </modal-popover>

    <modal-popover id="testResult" title="Test SAML settings" size="large" :displayed-id="modalDisplayedId"
                   @close-modal="closeTestModal">
      <template v-slot:content>
        <div v-if="testResult.successful && !showTestEmailWarning">
          <div class="info-box info-box-success">
            The SAML test was successful.
          </div>
        </div>
        <div v-else-if="testResult.successful && showTestEmailWarning">
          <div class="info-box info-box-warning">
            The SAML test was successful. However, the NameID appears
            to match the Email attribute. This means that if a user's email address changes, a new user account will be
            created on eCase. To prevent this, you must configure your IdP to send an immutable unique identifier for every user.
          </div>
        </div>
        <div v-else>
          <div class="info-box info-box-danger">
            <p>The SAML test failed for the following reason:</p>
            <p><strong>{{ testResult.errorDescription }}</strong></p>
            <p v-if="testResult.errorMitigation">
              {{ testResult.errorMitigation }}
            </p>
          </div>
        </div>
        <div v-if="Object.keys(testResult.recognisedAttributes).length > 0">
          <p>The following attributes were received:</p>
          <ul class="padded-list">
            <li v-for="(value, name) in testResult.recognisedAttributes" :key="name">
              <strong>{{ name }}:</strong> {{ value }}
            </li>
          </ul>
        </div>

        <div v-if="testResult.successful">
          <p>
            Please check the attribute values are correct.
            If they are not, eCase user accounts may be created with incorrect details.
          </p>
          <p>You may now save your SAML settings.</p>
        </div>

        <details-vue v-if="!testResult.successful && testResult.samlResponseXml" summary="Show SAML Response XML">
          <p>eCase received the following SAML Response from your IdP:</p>
          <textarea v-model="testResult.samlResponseXml" readonly="readonly" rows="5" />
        </details-vue>
      </template>
    </modal-popover>
  </div>
</template>

<script>
import { useVNavigationGuard } from "core/components/composable/v-navigation-guard.ts";
import FullPageLoadMixin from "core/components/mixins/FullPageLoadMixin.js";
import DetailsVue from "core/components/DetailsVue.vue";
import showFlashMessage, { dismissFlashMessages } from "core/js/flash-message.ts";
import springUrl from "core/js/spring-url.js";
import ModalPopover from "core/components/ModalPopover.vue";
import { useModalPopover } from "core/components/composable/modal-popover.ts";
import { getSamlSettings, saveSamlSettings, saveSamlSettingsToUrl } from "authentication/js/api/saml-settings.api.ts";

export default {
  components: { ModalPopover, DetailsVue },
  mixins: [FullPageLoadMixin],
  props: {
    urls: { type: Object, required: true },
    credentialLoginEnabled: { type: Boolean, required: true },
    disableAllowed: { type: Boolean, required: true },
  },
  setup() {
    const { modalDisplayedId, displayModal, closeModal } = useModalPopover();
    const { initNavigationGuard, resetNavigationGuard } = useVNavigationGuard();

    return {
      modalDisplayedId,
      displayModal,
      closeModal,
      initNavigationGuard,
      resetNavigationGuard,
    };
  },
  data() {
    return {
      settings: {},
      successfulTestSettings: null,
      validationErrors: {},
      testButtonDisabled: false,
      savePending: false,
      testStarted: false,
      testResult: {
        successful: false,
        recognisedAttributes: {},
        samlResponseXml: "",
      },
      showSecondaryCertificate: false,
    };
  },
  computed: {
    testRequired() {
      if (!this.settings.enabled) {
        return false;
      } else if (this.successfulTestSettings != null && this.testResult.successful) {
        return ["idpUrl", "idpEntityId", "primaryX509Certificate", "secondaryX509Certificate", "spEntityId"]
          .some(property => this.settings[property] !== this.successfulTestSettings[property]);
      } else {
        return true;
      }
    },
    saveDisabled() {
      return this.testRequired || this.savePending || this.showDisableNotAllowedMessage;
    },
    settingsToSubmit() {
      const settingsCopy = { ...this.settings };
      if (!this.showSecondaryCertificate) {
        delete settingsCopy.secondaryX509Certificate;
      }
      return settingsCopy;
    },
    showTestEmailWarning() {
      return this.testResult.recognisedAttributes.Email === this.testResult.recognisedAttributes.NameID;
    },
    showCredentialLoginEnabledWarning() {
      return this.settings.enabled && this.credentialLoginEnabled;
    },
    showDisableNotAllowedMessage() {
      return !this.settings.enabled && !this.disableAllowed;
    },
  },
  async created() {
    await this.fullPageLoad(this.loadSettings());
    this.initNavigationGuard(this, "settings");
    // Required to force IE 11 to see updates to localStorage made by other tabs
    window.onstorage = () => { /* No callback */ };
  },
  mounted() {
    // Add so this can be called in an integration test
    window.samlSettingsComponent = {
      handleTestResult: this.handleTestResult,
    };
  },
  methods: {
    async loadSettings() {
      this.settings = await getSamlSettings();
      this.showSecondaryCertificate = this.settings.secondaryX509Certificate != null;
    },
    async startTest(event) {
      this.validationErrors = {};
      dismissFlashMessages();
      try {
        this.testButtonDisabled = true;

        const saveResult = await saveSamlSettingsToUrl(this.settingsToSubmit, this.urls.saveTestSettingsUrl);
        if (saveResult.valid) {
          this.displayModal("startTest", event);
        } else {
          this.validationErrors = saveResult.validationErrors;
          showFlashMessage("Please correct the marked errors.", { displayType: "danger" });
        }
      } finally {
        this.testButtonDisabled = false;
      }
    },
    openIdpWindow() {
      window.localStorage.removeItem("samlTestResult");
      this.testStarted = true;
      window.open(springUrl(this.urls.initiateTestUrl));

      // SBA-633: poll localStorage for a result - this will be written by the child window when the test completes
      const interval = setInterval(() => {
        const result = window.localStorage.getItem("samlTestResult");
        if (result) {
          clearInterval(interval);
          this.handleTestResult(result);
          window.localStorage.removeItem("samlTestResult");
        }
      }, 100);
    },
    async saveSettings() {
      this.validationErrors = {};
      dismissFlashMessages();
      try {
        this.savePending = true;

        const saveResult = await saveSamlSettings(this.settingsToSubmit);
        if (saveResult.valid) {
          showFlashMessage("Settings saved successfully.", { displayType: "success" });
          this.resetNavigationGuard();
        } else {
          this.validationErrors = saveResult.validationErrors;
          showFlashMessage("Please correct the marked errors.", { displayType: "danger" });
        }
      } finally {
        this.savePending = false;
      }
    },
    backClick() {
      this.resetNavigationGuard();
      window.history.back();
    },
    handleTestResult(testResult) {
      // Don't allow test results to be handled if they closed the modal as they may have subsequently changed the settings
      if (this.testStarted) {
        this.testResult = { ...this.testResult, ...JSON.parse(testResult) };
        this.displayModal("testResult");
        if (this.testResult.successful) {
          this.successfulTestSettings = { ...this.successfulTestSettings, ...this.settings };
        }
      }
    },
    closeTestModal() {
      this.testStarted = false;
      this.closeModal();
    },
  },
};
</script>
<style>
.padded-list {
  padding-bottom: 1em;
  padding-top: 0.5em;
}
</style>
