<template>
  <div>
    <div class="info-box info-box-info">
      <p>
        IP Whitelist is used to control a list of trusted IP addresses from which your users can access eCase. Only
        IPv4 Addresses are supported.
      </p>
    </div>

    <div class="setoutForm regular-form-width">
      <div class="row">
        <div class="three columns">
          <span id="enable-whitelist-label" class="prompt west">Enable Whitelist?</span>
        </div>
        <div class="nine columns radio-or-tickbox-group">
          <div class="container" role="radiogroup" aria-labelledby="enable-whitelist-label">
            <div class="row">
              <div class="two columns">
                <label class="radio-label" for="whitelist-enabled-enabled">
                  <input id="whitelist-enabled-enabled" v-model="ipWhitelistEnabled" type="radio"
                         name="whitelist-enabled" :value="true"> Enabled
                </label>
              </div>
              <div class="two columns">
                <label class="radio-label" for="whitelist-enabled-disabled">
                  <input id="whitelist-enabled-disabled" v-model="ipWhitelistEnabled" type="radio"
                         name="whitelist-enabled" :value="false"> Disabled
                </label>
              </div>
              <div class="eight columns" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list-actions">
      <div class="menu-out">
        <button ref="addIp" class="button small-button" @click.prevent="addIp">
          Add new IP range
        </button>
      </div>
    </div>

    <template v-if="customIps.length">
      <table id="whitelistTable" class="setoutList" aria-describedby="whitelistTable">
        <thead>
          <tr>
            <th id="whitelist-list-table-status" class="whitelist-list-table__cell--status">
              <span class="screen-reader-only">Status</span>
            </th>
            <th>Name<span class="mand-label" aria-hidden="true">optional</span></th>
            <th id="whitelist-list-table-start-ip" class="whitelist-list-table__cell--ip">
              Start IP
            </th>
            <th id="whitelist-list-table-end-ip" class="whitelist-list-table__cell--ip">
              End IP
            </th>
            <th id="whitelist-list-table-actions">
              <span class="screen-reader-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(customIp, index) in customIps" :key="customIp.id">
            <td>
              <ul class="menu-out">
                <li>
                  <span class="label small-label"
                        :class="customIp.enabled ? ipWhitelistEnabled ? 'green-label' : 'grey-label' : 'red-label'">
                    {{ customIp.enabled ? ipWhitelistEnabled ? "Enabled" : "Enabled but inactive" : "Disabled" }}
                  </span>
                </li>
                <li v-if="customIp.enabled && !ipWhitelistEnabled">
                  <span class="extra-info">The whitelist is disabled</span>
                </li>
              </ul>
            </td>
            <td>
              <label :for="`name-${index+1}`">
                <span class="screen-reader-only">Range name for row {{ index + 1 }}</span>
              </label>
              <input :id="`name-${index+1}`" ref="ipRangeName" v-model="customIp.name" v-focus-on-create class="word-break-field"
                     type="text" maxlength="1000" aria-required="false">
            </td>
            <td>
              <label :for="`startip-${index+1}`">
                <span class="screen-reader-only">Start IP for row {{ index + 1 }}</span>
              </label>
              <input :id="`startip-${index+1}`" v-model="customIp.startIp" class="word-break-field" type="text"
                     maxlength="15" aria-required="true">
              <div v-if="customIp.startErrorMessage" class="error-message">
                {{ customIp.startErrorMessage }}
              </div>
            </td>
            <td>
              <label :for="`endip-${index+1}`">
                <span class="screen-reader-only">End IP for row {{ index + 1 }}</span>
              </label>
              <input :id="`endip-${index+1}`" v-model="customIp.endIp" class="word-break-field" type="text" maxlength="15"
                     aria-required="true">
              <div v-if="customIp.endErrorMessage" class="error-message">
                {{ customIp.endErrorMessage }}
              </div>
            </td>
            <td>
              <ul class="menu-out">
                <li v-if="customIp.enabled">
                  <button class="link-button small-button button icon-minus"
                          role="link" type="button" @click="disableIp(customIp)">
                    Disable
                    <span class="screen-reader-only">
                      {{ formatIpRange(customIp) }} on row {{ index + 1 }}
                    </span>
                  </button>
                </li>
                <li v-else>
                  <button class="link-button small-button button icon-plus" role="link"
                          type="button" @click="enableIp(customIp)">
                    Enable
                    <span class="screen-reader-only">
                      {{ formatIpRange(customIp) }} on row {{ index + 1 }}
                    </span>
                  </button>
                </li>
                <li>
                  <button class="link-button small-button button icon-bin" role="link"
                          type="button" @click="showRemoveIp(customIp)">
                    Remove
                    <span class="screen-reader-only">
                      IP range {{ formatIpRange(customIp) }} on row {{ index + 1 }}
                    </span>
                  </button>
                </li>
              </ul>
            </td>
            <div v-if="customIp.errorMessage">
              <p class="error-message">
                {{ customIp.errorMessage }}
              </p>
            </div>
          </tr>
        </tbody>
      </table>

      <div class="list-actions">
        <div class="menu-out">
          <button class="button small-button" @click.prevent="addIp">
            Add new IP range
          </button>
        </div>
      </div>
    </template>
    <div v-else class="info-box info-box-info">
      <p>No IPs are currently whitelisted</p>
    </div>

    <div class="floating-actions-bar fixedsticky">
      <ul class="menu-out flow-across">
        <li>
          <button :disabled="disableButtons || undefined" class="primary-button" @click.prevent="saveWhitelist">
            Save<span class="screen-reader-only"> changes to IP Whitelist</span>
          </button>
        </li>
        <li>
          <a :disabled="disableButtons || undefined" class="button link-button" href="#" @click.prevent="backClick">
            Cancel<span class="screen-reader-only"> changes to IP Whitelist</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useVNavigationGuard } from "core/components/composable/v-navigation-guard.ts";
import FullPageLoadMixin from "core/components/mixins/FullPageLoadMixin.js";
import { ajaxGet, ajaxPut } from "core/js/spring-ajax.js";
import showFlashMessage from "core/js/flash-message.ts";
import { displayModalConfirm } from "core/js/modal-confirm.js";
import announce from "core/js/announce.js";

export default {
  directives: {
    focusOnCreate: {
      mounted(el) {
        el.focus();
      },
    },
  },
  mixins: [FullPageLoadMixin],
  setup() {
    const { initNavigationGuard, resetNavigationGuard } = useVNavigationGuard();

    return { initNavigationGuard, resetNavigationGuard };
  },
  data() {
    return {
      ipWhitelistEnabled: false,
      customIps: [],
      disableButtons: false,
    };
  },
  async created() {
    await this.fullPageLoad(this.loadWhitelistData());
    this.initNavigationGuard(this, "ipWhitelistEnabled", "customIps");
  },
  methods: {
    async loadWhitelistData() {
      const whitelistData = await ajaxGet({
        url: "/authentication/rest/api/settings/ip-whitelist",
      });

      this.ipWhitelistEnabled = whitelistData.ipWhitelistEnabled;
      this.customIps = whitelistData.customIps;
    },
    addIp() {
      const newIp = {
        startIp: "",
        endIp: "",
        enabled: true,
      };
      this.customIps.push(newIp);
      announce("IP range added");
    },
    enableIp(ip) {
      ip.enabled = true;
      announce("IP range enabled");
    },
    disableIp(ip) {
      ip.enabled = false;
      announce("IP range disabled");
    },
    showRemoveIp(ip) {
      const confirmMessage = `Are you sure you want to delete the ${this.formatIpRange(ip)}`;
      displayModalConfirm(`${confirmMessage}?`, { confirmText: "Remove IP range" }, () => this.removeIp(ip));
    },
    removeIp(ip) {
      announce("IP range removed");
      const ipIndex = this.customIps.indexOf(ip);
      this.customIps.splice(ipIndex, 1);
      if (this.customIps.length === 0) {
        // Removed last ip, focus on the add IP button
        this.$refs.addIp.focus();
      } else {
        // Focus on the name field of the IP before this one
        // if we are removing the first then focus on the next one
        this.$refs.ipRangeName[Math.max(ipIndex - 1, 0)].focus();
      }
    },
    validate() {
      let valid = true;

      const foundIps = [];

      this.customIps.forEach(customIp => {
        if (!customIp.startIp) {
          customIp.startErrorMessage = "You must enter a Start IP.";
          valid = false;
        } else if (!this.isValidIp(customIp.startIp)) {
          customIp.startErrorMessage = "The Start IP is not a valid IP.";
          valid = false;
        } else {
          delete customIp.startErrorMessage;
        }

        if (!customIp.endIp) {
          customIp.endErrorMessage = "You must enter an End IP.";
          valid = false;
        } else if (!this.isValidIp(customIp.endIp)) {
          customIp.endErrorMessage = "The End IP is not a valid IP.";
          valid = false;
        } else {
          delete customIp.endErrorMessage;
        }

        if (valid && this.compareIps(customIp.startIp, customIp.endIp) > 0) {
          customIp.endErrorMessage = "The End IP must not be lower than the Start IP.";
          valid = false;
        }

        if (valid) {
          if (foundIps.find(e => e.startIp === customIp.startIp && e.endIp === customIp.endIp)) {
            customIp.startErrorMessage = "The Start and End IP must be unique.";
            valid = false;
          } else {
            foundIps.push(customIp);
          }
        }
      });
      return valid;
    },
    isValidIp(ip) {
      const sections = ip.split(".");
      if (sections.length !== 4) {
        return false;
      }
      return sections.every(section => {
        const number = Number(section);
        // Valid number between 0 and 255
        if (Number.isNaN(number) || number < 0 || number > 255) {
          return false;
        }
        // Disallow leading 0's as it is not supported by InetAddress `No clear standard exists
        // on whether to treat leading 0's as decimal or octet`
        return !(section.length > 1 && section[0] === "0");
      });
    },
    compareIps(ip1, ip2) {
      const ip1Sections = ip1.split(".");
      const ip2Sections = ip2.split(".");

      for (let i = 0; i < 4; i++) {
        if (Number(ip1Sections[i]) > Number(ip2Sections[i])) {
          return 1;
        }
        if (Number(ip1Sections[i]) < Number(ip2Sections[i])) {
          return -1;
        }
      }
      return 0;
    },
    formatIpRange(ip) {
      let message = "IP range";
      if (ip.name) {
        message += ` ${ip.name}`;
      }

      if (ip.startIp && ip.endIp) {
        let ips = `${ip.startIp} to ${ip.endIp}`;
        if (ip.name) {
          ips = `(${ips})`;
        }
        message += ` ${ips}`;
      }
      return message;
    },
    async saveWhitelist() {
      if (this.validate()) {
        this.disableButtons = true;

        const data = {
          ipWhitelistEnabled: this.ipWhitelistEnabled,
          customIps: this.customIps,
        };

        try {
          await ajaxPut({ url: "/authentication/rest/api/settings/ip-whitelist", data, errorHandler: (e) => { throw e; } });
          this.resetNavigationGuard();
          showFlashMessage("Your changes have been saved.", { displayType: "success" });
          this.disableButtons = false;
        } catch (error) {
          const errorText = error && error.responseJSON ? error.responseJSON.message
            : "Sorry, there was a problem saving your changes";
          this.showErrorFlash(errorText);
          this.disableButtons = false;
        }
      } else {
        this.showErrorFlash("Please correct the errors on this page before saving your changes");
      }
    },
    backClick() {
      this.resetNavigationGuard();
      window.history.back();
    },
    showErrorFlash(msg) {
      showFlashMessage(msg, { displayType: "danger" });
    },
  },
};
</script>
