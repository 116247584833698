<template>
  <div class="reCAPTCHA-container">
    <div v-if="reCaptchaLibraryLoaded" :id="reCaptchaWrapperId" class="g-recaptcha" :data-sitekey="reCaptchaSiteKey"
         :data-callback="globalReCaptchaResponseCallbackFunction"
         :data-expired-callback="globalReCaptchaExpiredCallbackFunction"/>
  </div>
</template>

<script>
import { useGoogleReCaptchaScript } from "authentication/js/composable/google-recaptcha-script";

export default {
  emits: ["onReCaptchaAttempt", "onReCaptchaExpired"],
  setup(_, { emit }) {
    function handleReCaptchaResponse(response) {
      emit("onReCaptchaAttempt", response);
    }

    function handleReCaptchaExpired() {
      emit("onReCaptchaExpired");
    }

    const {
      reCaptchaSiteKey,
      reCaptchaWrapperId,
      globalReCaptchaResponseCallbackFunction,
      globalReCaptchaExpiredCallbackFunction,
      reCaptchaLibraryLoaded,

      loadGoogleReCaptchaScript,
    } = useGoogleReCaptchaScript(handleReCaptchaResponse, handleReCaptchaExpired);

    return {
      reCaptchaSiteKey,
      reCaptchaWrapperId,
      globalReCaptchaResponseCallbackFunction,
      globalReCaptchaExpiredCallbackFunction,
      reCaptchaLibraryLoaded,

      loadGoogleReCaptchaScript,
    };
  },
  async mounted() {
    await this.loadGoogleReCaptchaScript();
  },
};
</script>

<style lang="scss">

.reCAPTCHA-container {
  margin-bottom: 1em;
}

</style>
