<template>
  <login-layout>
    <template #body>
      <template v-if="resetPasswordSuccess">
        <atom-info-box priority="success">
          Thank you. An email has been sent to you with further instructions on completing your password reset request.
        </atom-info-box>
      </template>

      <template v-else>
        <atom-info-box v-if="validationError.reCaptchaFailed" priority="danger" role="alert">
          You must complete the reCAPTCHA challenge before submitting your request.
        </atom-info-box>

        <atom-info-box v-else-if="validationError.message" priority="danger" role="alert">
          {{ validationError.message }}
        </atom-info-box>

        <atom-info-box v-else-if="resetPasswordFailure" priority="danger" role="alert">
          Unfortunately, it was not possible to request a password reset. Please try again or contact support.
        </atom-info-box>

        <atom-text-input id="username" v-model="username" label="Email address"
                         :error-message="validationError.username"/>

        <template v-if="reCaptchaRequired">
          <google-re-captcha-check @on-re-captcha-attempt="setReCaptchaResponse" @on-re-captcha-expired="voidReCaptchaResponse"/>
        </template>

        <atom-button label="Request password reset" type="primary" :disabled="loading || undefined"
                     @click="submitResetPasswordRequest"/>
        <atom-button label="Cancel" type="link-button" @click.prevent="$router.back();"/>
      </template>
    </template>
  </login-layout>
</template>

<script>
import LoginLayout from "authentication/components/login/layout/LoginLayout.vue";
import AtomInfoBox from "core/components/atom/infoBox/AtomInfoBox.vue";
import AtomTextInput from "core/components/atom/textInput/AtomTextInput.vue";
import AtomButton from "core/components/atom/button/AtomButton.vue";
import GoogleReCaptchaCheck from "authentication/components/login/components/GoogleReCaptchaCheck.vue";
import { useLoginPropertiesStore } from "authentication/stores/login-properties.store";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { template } from "lodash";
import { getFirstValidationError } from "core/js/validation/validation-messages";
import { requestPasswordReset } from "authentication/js/api/password.api";
import announce from "core/js/announce";

export default {
  components: {
    AtomButton,
    LoginLayout,
    AtomTextInput,
    AtomInfoBox,
    GoogleReCaptchaCheck,
  },
  setup() {
    return {
      store: useLoginPropertiesStore(),

      v$: useVuelidate(),
    };
  },
  data() {
    return {
      loading: false,
      username: "",
      reCaptchaResponse: undefined,
      resetPasswordStatus: undefined,
      validationError: {},
    };
  },
  computed: {
    resetPasswordSuccess() {
      return this.resetPasswordStatus === "SUCCESS";
    },
    resetPasswordFailure() {
      return this.resetPasswordStatus === "FAILURE";
    },
    reCaptchaRequired() {
      return !this.store.loginProperties.ipWhitelistEnabled;
    },
  },
  validations() {
    return {
      username: { required, email },
    };
  },
  async created() {
    await this.store.fetchLoginProperties();
  },
  methods: {
    setReCaptchaResponse(response) {
      this.reCaptchaResponse = response;
    },
    voidReCaptchaResponse() {
      this.reCaptchaResponse = undefined;
    },
    async submitResetPasswordRequest() {
      this.validationError = {};
      this.loading = true;
      this.username = this.username.trim();

      const reCaptchaFailed = this.reCaptchaRequired && !this.reCaptchaResponse;
      if (reCaptchaFailed) {
        this.validationError.reCaptchaFailed = true;
      }

      try {
        if (await this.v$.$validate()) {
          if (!reCaptchaFailed) {
            const resetPasswordResult = this.reCaptchaRequired
              ? await requestPasswordReset(this.username, this.reCaptchaResponse) : await requestPasswordReset(this.username);
            this.resetPasswordStatus = resetPasswordResult.resetPasswordStatus;

            if (this.resetPasswordFailure) {
              this.v$.$reset();
              this.validationError = { message: resetPasswordResult.message };
            }
          }
        } else {
          this.validationError.username = getFirstValidationError(
            this.v$.username,
            "email address",
            { required: template("Enter an email address") },
          );

          announce(`There were some errors with your submission. ${this.validationError.username || ""}.`);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
