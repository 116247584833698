import {
  UserAuthenticationSettings,
  UserAuthenticationSettingsValidationErrors,
} from "authentication/js/api/user-authentication-settings.api";

export function validateUserAuthenticationSettings(
  userAuthenticationSettings: UserAuthenticationSettings,
): UserAuthenticationSettingsValidationErrors {
  const validationErrors = {
    timeoutSettings: {},
  } as UserAuthenticationSettingsValidationErrors;
  if (!Number.isSafeInteger(+userAuthenticationSettings.timeoutSettings.duration)) {
    validationErrors.timeoutSettings.duration = "Duration must be a valid whole number";
  }
  return validationErrors;
}