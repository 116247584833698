import { ref, nextTick } from "vue";
import showFlashMessage from "core/js/flash-message";

const GOOGLE_RECAPTCHA_CLIENT_LIBRARY_SRC = "https://www.google.com/recaptcha/api.js";

export function useGoogleReCaptchaScript(
  handleReCaptchaResponse: (response: string) => void,
  handleReCaptchaExpired: () => void,
) {
  const reCaptchaSiteKey = window.ECASE.ReCaptchaSiteKey;
  const reCaptchaWrapperId = "googleRecaptchaWidgetWrapper";
  const globalReCaptchaResponseCallbackFunction = "ECASE_reCaptchaResponseCallback";
  const globalReCaptchaExpiredCallbackFunction = "ECASE_reCaptchaExpiredCallback";
  const reCaptchaLibraryLoaded = ref(false);

  function handleScriptError(error: string | Event) {
    showFlashMessage(
      "There was an error in loading the reCAPTCHA widget. "
      + "Please contact your local support desk if this issue persists.",
      { displayType: "danger" },
    );

    throw new Error(typeof error === "string" ? error : error.type);
  }

  function onScriptLoaded() {
    reCaptchaLibraryLoaded.value = true;
  }

  async function loadGoogleReCaptchaScript() {
    if (!window.grecaptcha) {
      // The reCAPTCHA client library JS loads another bit of JS, which adds another script tag to the document that actually
      // loads the core library code. So there is no sufficient means in detecting once *all* of the code has loaded.
      // So instead of programmatically rendering the widget, the reCAPTCHA vue component will use an HTML element that
      // automatically shows the widget once the required code has loaded. As part of this, that HTML element will need
      // a global reference to the JS function for handling the reCAPTCHA callback. This function cannot be scoped to any
      // of the Vue code, so will have to use a window function that calls the required Vue function. Same for expired callback.

      // eslint-disable-next-line
      (window as any)[globalReCaptchaResponseCallbackFunction] = (recaptchaResponseToken: string) => { // @ts-ignore
        handleReCaptchaResponse(recaptchaResponseToken);
      };

      // eslint-disable-next-line
      (window as any)[globalReCaptchaExpiredCallbackFunction] = () => { // @ts-ignore
        handleReCaptchaExpired();
      };

      const script = document.createElement("script");
      script.src = GOOGLE_RECAPTCHA_CLIENT_LIBRARY_SRC;
      script.onload = onScriptLoaded;
      script.onerror = handleScriptError;
      script.async = true;

      document.head.appendChild(script);
    } else {
      // This means the script has already loaded and widget was rendered, but user has navigated away from the page and
      // returned back to it again, so must manually re-render the widget
      onScriptLoaded();

      // Ensure widget wrapper is visible on screen
      await nextTick();

      // Programmatically render the widget
      window.grecaptcha.render(reCaptchaWrapperId, {
        sitekey: reCaptchaSiteKey,
        callback: handleReCaptchaResponse,
        "expired-callback": handleReCaptchaExpired,
      });
    }
  }

  return {
    reCaptchaSiteKey,
    reCaptchaWrapperId,
    globalReCaptchaResponseCallbackFunction,
    globalReCaptchaExpiredCallbackFunction,
    reCaptchaLibraryLoaded,

    loadGoogleReCaptchaScript,
  };
}
