<template>
  <login-layout :loading="loading" loading-text="Verifying your identity...">
    <template #body>
      <atom-info-box v-if="tokenParamMissing" priority="danger">
        This token link is invalid. Make sure it is the same as the one provided in the email you received.
      </atom-info-box>

      <template v-if="loginFailure">
        <atom-info-box v-if="invalidToken" priority="danger" role="alert">
          <p class="ecase-sign-in__body-text">
            The sign-in token used was invalid. This means that the token may have expired or was already used.
          </p>
          <p class="ecase-sign-in__body-text">
            Please try to <a :href="loginUrl">sign in</a> again.
          </p>
        </atom-info-box>

        <atom-info-box v-else-if="validationError.message" priority="danger" role="alert">
          {{ validationError.message }}
        </atom-info-box>

        <atom-info-box v-else priority="danger" role="alert">
          There was a problem with processing your sign-in token. Please contact your local support desk.
        </atom-info-box>
      </template>
    </template>
  </login-layout>
</template>

<script>
import LoginLayout from "authentication/components/login/layout/LoginLayout.vue";
import AtomInfoBox from "core/components/atom/infoBox/AtomInfoBox.vue";
import { loginWithToken } from "authentication/js/api/login.api.ts";
import springUrl from "core/js/spring-url.js";

const LOGIN_PATH = "/authentication/login";

export default {
  components: {
    LoginLayout,
    AtomInfoBox,
  },
  data() {
    return {
      loading: true,
      tokenParamMissing: false,
      loginStatus: undefined,
      validationError: {},
    };
  },
  computed: {
    loginSuccess() {
      return this.loginStatus === "SUCCESS";
    },
    loginFailure() {
      return this.loginStatus === "FAILURE";
    },
    invalidToken() {
      return this.validationError && this.validationError.reason === "INVALID_TOKEN";
    },
    loginUrl() {
      return springUrl(LOGIN_PATH);
    },
  },
  async mounted() {
    if (this.$route.query.token) {
      await this.submitToken(this.$route.query.token);
    } else {
      this.tokenParamMissing = true;
      this.loading = false;
    }
  },
  methods: {
    async submitToken(token) {
      try {
        const loginResult = await loginWithToken(token);
        this.loginStatus = loginResult.loginStatus;

        if (this.loginSuccess) {
          window.location.assign(loginResult.redirectUrl);
        } else {
          this.validationError = {
            reason: loginResult.loginFailure.failureReason,
            message: loginResult.loginFailure.message,
          };
          this.loading = false;
        }
      } catch (err) {
        // setting loading to false in the catch as opposed to finally so that the loading animation remains visible
        // while the page is redirecting on successful token verification. Hide loading only in cases of unexpected errors.
        this.loading = false;
        throw err;
      }
    },
  },
};
</script>
